import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WohnkreditZuZweit = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Wohnkredit zu zweit – Vor- und Nachteile" showCalc={false} />
            <Article>
                <p>
                    Bei zahlreichen Paaren, die sich in einer langfristigen Beziehung befinden, kommt an einem
                    bestimmten Punkt der Wunsch nach einem gemeinsamen Zuhause auf. Ist die Gretchenfrage “mieten oder
                    kaufen?” erst einmal zugunsten der zweiten Option entschieden, so stellt sich sofort die nächste:
                    Sollen die Partner:innen den Kredit zusammen aufnehmen? Welche Vorteile und Risiken birgt diese Form
                    der{" "}
                    <Link to="/artikel/hausfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Haus-
                    </Link>{" "}
                    bzw.{" "}
                    <Link to="/artikel/wohnungsfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Wohnungsfinanzierung
                    </Link>
                    ? Und wie kann man sich als unverheiratetes Paar absichern? Darüber gibt der folgenden Artikel
                    Auskunft.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Vorteile der Immobilienfinanzierung zu zweit</h2>
                <p>
                    Die Vorteile einer gemeinsamen Immobilienfinanzierung liegen zunächst im rein Finanziellen: Sofern
                    beide Partner:innen über ein regelmäßiges Einkommen verfügen, lassen sich bei den Verhandlungen mit
                    dem Kreditinstitut meist günstigere Konditionen erzielen, als sie Einzelpersonen in der Regel
                    erhalten. Da das frei verfügbare Einkommen von zwei Personen für die Rückzahlung der Finanzierung
                    verwendet werden kann, ist es für Paare möglich, höhere monatliche Raten zu wählen, was sich
                    zugleich positiv auf die Laufzeit des Kredites auswirkt: Diese verkürzt sich damit, was euch vor
                    Risiken wie zukünftigen Zinssteigerungen auf dem Finanzmarkt schütz. Falls ihr wissen wollt, wie
                    sich die Höhe der monatlichen Rate konkrete auf die Laufzeit eures Kredits auswirkt, dann empfehlen
                    wir euch unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <p>
                    Auch die neuen Kreditregeln, die mit Juli 2022 in Kraft getreten sind, stellen bei einer
                    Finanzierung zu zweit ein geringeres Problem dar: Die Eigenkapitalmenge von 20 %, die
                    Kreditnehmer:innen nun in die Finanzierung von Immobilien einbringen müssen, lässt sich gemeinsam um
                    einiges leichter beschaffen. Ein weiterer Aspekt, der die Aufnahme eines Wohnkredits zu zweit zur
                    bedenkenswerten Option macht, ist die damit verbundenen Risikominimierung. Sollte eine:r der beiden
                    Kreditnehmer:innen, beispielsweise durch den Verlust seines oder ihres Jobs vorübergehend
                    zahlungsunfähig werden, so kann der:die andere die Rückzahlung der Kreditsumme - ggf. zu angepassten
                    Konditionen - fortsetzen. Die Streuung des Risikos wirkt sich zudem positiv auf die Bereitschaft von
                    Kreditinstituten aus, euch einen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    zu gewähren.
                </p>
                <p>
                    Neben dem finanziellen spricht noch ein weiterer Aspekt für die gemeinsame Finanzierung einer
                    Immobilie: Zu zweit könnt ihr einander bei den zahlreich Formalitäten unterstützen, die es vor,
                    während und nach dem Immobilienkauf zu bewältigen gilt. Vier Augen sehen bekanntlich mehr als zwei
                    und bei Kerzenschein und einem guten Glas Wein werden nicht nur die Formulare knistern!
                </p>
                <hr />

                <h2>Risiken der gemeinsamen Finanzierung</h2>
                <p>
                    Das größte und naheliegendste Risiko bei einer gemeinsamen Finanzierung ist natürlich die Trennung.
                    Gerade unverheiratete Paare sollten sich vor einer gemeinsamen Immobilienfinanzierung deshalb
                    entsprechend absichern - idealerweise durch einen Partnerschaftsvertrag, der nicht nur das Vorgehen
                    im Trennungs-, sondern auch im Falle des Ablebens eines:einer Partner:in regelt. Festgehalten wird
                    in einem solchen Vertrag etwa, wer die Immobilie nach dem Ende der Beziehung weiterhin bewohnen darf
                    und welche Summe als Ausgleichszahlung an die anderen Partei zu entrichten ist.
                </p>
                <p>
                    Doch auch die Belastung einer gemeinsamen Immobilienfinanzierung für den Beziehungsalltag sollte
                    nicht unterschätzt werden: Schließlich geht es dabei um beträchtliche Geldsummen und eine gemeinsame
                    Verpflichtung auf Jahrzehnte. Eine solche Entscheidung sollte daher keineswegs leichtfertig
                    getroffen werden. Auch ist es ratsam, diesen Schritt nicht allzu früh in der Beziehung zu setzen.
                    Denn es ist sicherlich vorteilhaft, bereits auf gewisse Erfahrungen im partnerschaftlichen Umgang
                    mit Belastungssituationen zurückgreifen zu können. Durch eine ungleiche finanzielle Belastung der
                    beiden Partner:innen bei der Rückzahlung des Kredits können zudem traditionelle partnerschaftliche
                    Hierarchien in die Beziehung eingeführt bzw. gefestigt werden, die zuvor nicht virulent waren.{" "}
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wohnkreditZuZweit"}></BreadcrumbList>
            <ArticleStructuredData page={"wohnkreditZuZweit"} heading={"Wohnkredit zu zweit – Vor- und Nachteile"} />
        </Layout>
    );
};

export default WohnkreditZuZweit;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.wohnkredit-zu-zweit", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
